import { useState } from 'react';
import { Diff } from '@gonfalon/launchpad-experimental';
import { RolePresetsBundle, RoleTemplate } from '@gonfalon/permissions';
import { getRolePresetsQuery, isRESTAPIError, usePatchRolePresets } from '@gonfalon/rest-api';
import { formatJSON } from '@gonfalon/strings';
import {
  Alert,
  Button,
  ButtonGroup,
  Dialog,
  DialogTrigger,
  IconButton,
  Modal,
  ModalOverlay,
  ProgressBar,
  snackbarQueue,
  toastQueue,
} from '@launchpad-ui/components';
import { useQuery } from '@tanstack/react-query';

import styles from './UpdateOOTBRolesModal.module.css';

export type RolePresetsResponse = {
  current: RolePresetsBundle;
  availableUpdate?: RolePresetsBundle;
};

const DiffViewer = ({ currentRole, previousRole }: { currentRole?: RoleTemplate; previousRole?: RoleTemplate }) => (
  <Diff
    title={currentRole?.name}
    original={previousRole ? formatJSON(JSON.stringify(previousRole)) : ''}
    compare={currentRole ? formatJSON(JSON.stringify(currentRole)) : ''}
    isJSON
    className={styles.diff}
  />
);

const RoleNavigation = ({
  currentIndex,
  totalUpdates,
  onPrevious,
  onNext,
}: {
  currentIndex: number;
  totalUpdates: number;
  onPrevious: () => void;
  onNext: () => void;
}) => (
  <div className={styles.roleControls}>
    <IconButton
      icon="chevron-left"
      onPress={onPrevious}
      isDisabled={currentIndex === 0}
      aria-label="Previous role"
      variant="minimal"
    />
    <p>
      Reviewing {currentIndex + 1} of {totalUpdates}
    </p>
    <IconButton
      icon="chevron-right"
      onPress={onNext}
      isDisabled={currentIndex === totalUpdates - 1}
      aria-label="Next role"
      variant="minimal"
    />
  </div>
);

export const UpdateOOTBRolesModal = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: rolePresets,
    isLoading,
    error,
    isError,
  } = useQuery(
    getRolePresetsQuery({
      query: {
        expand: 'roleTemplates',
      },
    }),
  );

  const { mutate: updateRolePresets, isPending } = usePatchRolePresets();

  const currentRoles = rolePresets?.current.roleTemplates;
  const updatedRoles = rolePresets?.availableUpdate?.roleTemplates;
  const totalUpdates = updatedRoles?.length ?? 0;

  const currentRole = updatedRoles?.[currentIndex];
  const previousRole = currentRoles?.find((r) => r.key === currentRole?.key);

  const handleNext = () => setCurrentIndex((prev) => Math.min(prev + 1, totalUpdates - 1));
  const handlePrevious = () => setCurrentIndex((prev) => Math.max(prev - 1, 0));
  const handleClose = () => setIsOpen(false);

  const handleApplyChanges = () => {
    if (!rolePresets?.availableUpdate) {
      return;
    }

    updateRolePresets(
      {
        body: {
          bundleVersion: rolePresets.availableUpdate.bundleVersion,
        },
      },
      {
        onSuccess: () => {
          toastQueue.add({ title: 'Role presets updated', status: 'success' });
          handleClose();
        },
        onError: (err: Error) => {
          snackbarQueue.add({
            description: isRESTAPIError(err) ? err?.message : 'Failed to update role presets. Try again later.',
            status: 'error',
          });
        },
      },
    );
  };

  const renderModalContent = () => {
    if (isError) {
      return (
        <Alert status="error">
          {isRESTAPIError(error) ? error?.message : 'Failed to load role presets. Please try again later.'}
        </Alert>
      );
    }

    if (isLoading) {
      return (
        <div className={styles.loadingState}>
          <ProgressBar size="medium" isIndeterminate />
          <p>Loading role updates...</p>
        </div>
      );
    }

    return (
      <>
        <p>
          Inspect what changed in the role policy before applying these changes to members in your organization who have
          been assigned this role.
        </p>
        <RoleNavigation
          currentIndex={currentIndex}
          totalUpdates={totalUpdates}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
        <DiffViewer currentRole={currentRole} previousRole={previousRole} />
        <ButtonGroup>
          <Button variant="minimal" onPress={handleClose} isDisabled={isPending}>
            Skip for now
          </Button>
          <Button variant="primary" onPress={handleApplyChanges} isDisabled={isPending}>
            Apply all changes
          </Button>
        </ButtonGroup>
      </>
    );
  };

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
      <Button variant="default" size="medium" className={styles.button} isDisabled={isLoading || isError}>
        View changes
      </Button>
      <ModalOverlay>
        <Modal size="large">
          <Dialog>
            <div className={styles.modalContent}>
              <h3>Updates roles</h3>
              {renderModalContent()}
            </div>
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
};
