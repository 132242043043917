import { getRolePresetsQuery } from '@gonfalon/rest-api';
import { Alert } from '@launchpad-ui/components';
import { useQuery } from '@tanstack/react-query';

import { UpdateOOTBRolesModal } from './UpdateOOTBRolesModal';

import styles from './UpdateOOTBRolesAlert.module.css';

export const UpdateOOTBRolesAlert = () => {
  const { data: rolePresets } = useQuery(getRolePresetsQuery({}));

  // Only show alert if there's an available update
  if (!rolePresets?.availableUpdate) {
    return null;
  }

  return (
    <Alert status="info" className={styles.alert}>
      <div className={styles.alertContent}>
        <h3>Updates to predefined roles</h3>
        <p>
          The role policy for predefined LaunchDarkly roles have been updated. View the changes before rolling out this
          update across members in your organization.
        </p>
      </div>
      <div className={styles.alertActions}>
        <UpdateOOTBRolesModal />
      </div>
    </Alert>
  );
};
